import {
  AddMediaToTeamDto,
  Configuration,
  GroupBoRestControllerApi,
  MatchBoRestControllerApi,
  PoolBoRestControllerApi,
  SeriesBoRestControllerApi,
  TeamMediaBoRestControllerApi,
  TeamBoRestControllerApi,
  TeamUpdateProposalBoRestControllerApi, DebugBoRestControllerApi
} from '../api-client/sport-bo-api';
import Axios from 'axios';
import {IMsalContext} from "@azure/msal-react/src/MsalContext";
import {loginRequest} from "../authConfig";

export const createApiConfiguration = async(auth: IMsalContext): Promise<Configuration> => {
  const token = (await auth.instance
    .acquireTokenSilent({
      ...loginRequest,
      account: auth.accounts[0],
    })).idToken;

  return new Configuration({
    basePath: process.env.REACT_APP_SPORT_API_URL,
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
  });
};

export const createSportGroupApi = async(auth: IMsalContext) => {
  return new GroupBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportMatchApi = async(auth: IMsalContext) => {
  return new MatchBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportPoolApi = async(auth: IMsalContext) => {
  return new PoolBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportSeriesApi = async(auth: IMsalContext) => {
  return new SeriesBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportTeamMediaApi = async(auth: IMsalContext) => {
  return new TeamMediaBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportTeamApi = async(auth: IMsalContext) => {
  return new TeamBoRestControllerApi(await createApiConfiguration(auth));
};

export const createSportTeamUpdateProposalApi = async(auth: IMsalContext) => {
  return new TeamUpdateProposalBoRestControllerApi(await createApiConfiguration(auth));
};

export const createDebugApi = async(auth: IMsalContext) => {
  return new DebugBoRestControllerApi(await createApiConfiguration(auth));
};

export const addMediaToTeam = (dto: AddMediaToTeamDto, file: File) => {
  const formData = new FormData();

  formData.append('file', file, file.name);
  formData.append(
    'dto',
    // API needs a JSON file
    new Blob([JSON.stringify(dto)], {
      type: 'application/json'
    })
  );

  return Axios.post(`${process.env.REACT_APP_SPORT_API_URL}/bo/teamMedia/addMedia`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
